.pagination-custom.ant-pagination {
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev {
		background-color: #c9af63;
		border: 1px solid #ffb61b;

		a {
			color: black;
		}

		&:hover {
			background-color: #c9af63;
			border: 1px solid #ffb61b;
		}
	}

	.ant-pagination-item-active {
		@apply bg-linearGold;
		border: 1px solid #ffb61b;

		a {
			color: black;
		}
	}

	.ant-pagination-prev button {
		background-color: #c9af63;
		border: 1px solid #ffb61b;

		&:hover {
			background-color: #c9af63;
			border: 1px solid #ffb61b;
		}

		.anticon {
			vertical-align: 0;
		}
	}

	.ant-pagination-next button {
		background-color: #c9af63;
		border: 1px solid #ffb61b;

		.anticon {
			vertical-align: 0;
		}

		&:hover {
			background-color: #c9af63;
			border: 1px solid #ffb61b;
		}
	}

	.ant-pagination-disabled button:disabled {
		background-color: #605641;
		border-color: #867649;
		color: #a79e7e;

		&:hover {
			background-color: #605641;
			border-color: #867649;
		}
	}
}
