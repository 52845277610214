.item-category svg {
  @apply w-4 fill-[#fdba74];
}

.item-active svg {
  @apply fill-white;
}

.item-active span {
  @apply text-white font-semibold;
}
