.select-custom {
	.ant-select-selector {
		width: 100%;
		background: transparent !important;
		border: none !important;
		outline: none !important;
		box-shadow: none !important;
		color: inherit;
		padding: 0 !important;

		.ant-select-selection-placeholder {
			display: flex;
			align-items: center;
			color: inherit;
		}
	}

	&.ant-select-open .ant-select-selector .ant-select-selection-item {
		color: inherit;
	}

	.ant-select-arrow {
		color: inherit;
	}
}

.select-dropdown-custom {
	&.ant-select-dropdown .ant-select-item {
		color: inherit;
	}

	&.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
		background: #362400 !important;
	}
}
