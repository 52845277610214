.range-custom .ant-picker-input input::placeholder {
  color: #e3b85d80;
  font-size: 12px;
}

.range-custom .ant-picker-outlined {
  background: #000000;
  border-color: #4e442e;
  box-shadow: none;
}

.range-custom .ant-picker-outlined:focus-within {
  border-color: #e3b85d;
}

.range-custom .ant-picker-input:focus-within {
  box-shadow: none;
}

.range-custom .ant-picker-suffix {
  color: #e3b85d;
}
