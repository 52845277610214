@tailwind base;
@tailwind components;
@tailwind utilities;

/* Sử dụng @layer để thêm class tùy chỉnh */
@layer utilities {
	.container-max-width {
		max-width: 960px;
	}
	.container-max-width {
		@apply mx-auto;
	}

	.scrollbar-hide::-webkit-scrollbar {
		display: none; /* For Chrome, Safari, and Opera */
	}
	.scrollbar-hide {
		-ms-overflow-style: none; /* For Internet Explorer and Edge */
		scrollbar-width: none; /* For Firefox */
	}

	.label-form {
		@apply relative text-[#edcd90] font-medium mb-2 before:content-[""] before:absolute before:w-[5px] before:h-[16px] before:rounded-xl before:bg-linearGold before:top-[50%] before:-translate-y-[50%];
	}

	.label-form span:first-of-type {
		@apply pl-3;
	}
}

* {
	font-family: 'Montserrat', serif;
	font-optical-sizing: auto;
}

.ant-empty-description {
	color: #fff !important;
}

.custom-modal {
	background: #ffffff;
	position: relative;
}

.custom-modal .ant-modal-content {
	padding: 0;
	background: inherit;
	border-radius: 0;
	position: static;
}

.custom-modal .ant-modal-header {
	background: none;
}

.custom-modal .ant-modal-close-x {
	color: inherit;
}

.popover-custom .ant-popover-inner {
	padding: inherit;
	background: inherit;
}

input:-webkit-autofill {
	-webkit-text-fill-color: #e3b85d !important;
	color: #e3b85d !important;
	-webkit-background-clip: text !important;
	background-clip: text !important;
	box-shadow: none !important;
}
